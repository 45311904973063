<template>
  <div class="lab_assgn_main">
    <div class="page_controls">
      <b-form-checkbox
        v-model="showLabStudent"
        switch
      >
        Show labs student
      </b-form-checkbox>
    </div>
    <b-button
      :variant="buttonVariant"
      class="ml-auto"
      @click="manageStudentsOfLab()"
    >
      <font-awesome-icon
        icon="cog"
        class="icon"
      />
      {{ buttonText }}
    </b-button>

    <div class="block mt-3">
      <b-table
        id="usersTable"
        :items="visibleStudents"
        :fields="fields"
        striped
        fixed
        outlined
        tbody-tr-class="user-row"
      >
        <template v-slot:cell(admit)="row">
          <input
            v-model="row.item.admit"
            type="checkbox"
            @change="recordAdmitData(row.item)"
          >
        </template>
        <template v-slot:cell(remove)="row">
          <input
            v-model="row.item.remove"
            type="checkbox"
            @change="recordAdmitData(row.item)"
          >
        </template>
        <template v-slot:cell(username)="row">
          <span>{{ row.item.username }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import { getStudents } from '@/shared/api/users-api';
  import { assignStudentInLab } from '@/shared/api/labs-api';
  import { getOrganizations } from '@/shared/api/organizations-api';
  import { displayNotification } from '@/shared/services/modal-service';

  export default {
    name: 'AppStudentAssignment',
    data: () => ({
      showLabStudent: true,
      organizations: [],
      users: [],
      admitstudents: [],
      // fields: [
        // { key: 'username', label: 'User', sortable: true },
        // { key: 'organization.id', label: 'Organization', sortable: true },
        // { key: 'admit' },
      // ],
      // showLabStudent: false,
      // flag: 1,
    }),
    computed: {
      visibleStudents() {
        let filteredUsers = [];
        if (this.showLabStudent) {
          filteredUsers = this.users.filter((user) => user.lab && (user.lab.some((lab) => lab.labId === this.labId) ));
        } else {
          filteredUsers = this.users.filter((user) => user.lab === null || user.lab.length === 0 || user.lab.some((lab) => lab.id !== this.labId));
        }
        return filteredUsers;
      },
      buttonText() {
        // Computed property to update the button text based on switch value
        return this.showLabStudent ? 'Remove student from lab' : 'Admit students to lab';
      },
      buttonVariant() {
        // Update button variant based on switch value
        return this.showLabStudent ? 'outline-danger' : 'outline-success';
      },
      fields() {
        const fields = [
          { key: 'username', label: 'User', sortable: true },
          // { key: 'organization.id', label: 'Organization', sortable: true },
        ];
        if (this.showLabStudent) {
          fields.push({ key: 'admit', label: 'Remove' });
        } else {
          fields.push({ key: 'admit', label: 'Admit' });
        }
        return fields;
      },
    },

    props: {
        labId: {
          type: String,
          required: true,
        },
    },

    async mounted() {
      this.organizations = await getOrganizations();
      await this.refresh();
    },
    async created() {
      await this.refresh();
    },
    methods: {
      async refresh() {
        this.users = await getStudents();
        this.admitstudents = [];
      },
      recordAdmitData(user) {
        if (user.admit) {
          this.admitstudents.push(user);
        } else {
          this.admitstudents = this.admitstudents.filter((item) => item.id !== user.id);
        }
        // Optionally, you could also log the admitData array to the console after each change:
        console.log(this.admitstudents);
      },
      async manageStudentsOfLab() {
        await assignStudentInLab(this.labId, this.admitstudents, this.showLabStudent)
          .then(() => {
            if (this.showLabStudent) {
              return displayNotification(this, { message: 'Students removed', variant: 'danger' });
            } else {
              return displayNotification(this, { message: 'Students admitted', variant: 'success' });
            }
          })
          .then(this.refresh)
          .catch(({ error, message }) => displayNotification(this, { title: error, message, variant: 'danger' }));
      },
    },
  };
</script>

<style scoped>
  /deep/ .user-row td:first-child {
    font-size: 16px;
    vertical-align: middle;
  }
  .lab_assgn_main{
    margin-top: 5px;
    padding: 0px 15px;
  }
</style>
