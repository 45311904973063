<!-- <template>
  <div class="org_container">
    <div class="table_div">
      <div class="org_main">
        <b-modal
          id="new-organization-modal"
          title="New Department"
          ok-title="Create"
          auto-focus-button="ok"
          :ok-disabled="isInputEmpty"
          @ok="createOrg"
          @cancel="newOrganizationName = ''"
          @close="newOrganizationName = ''"
        >
          <b-input
            v-model="newOrganizationName"
            autofocus
          />
        </b-modal>

        <b-button
          id="org_btn"
          v-b-modal.new-organization-modal
          variant="primary"
        >
          <font-awesome-icon
            icon="plus"
            class="icon"
          />
          Create new Department
        </b-button>

        <div class="block mt-3">
          <b-table
            :items="organizations"
            :fields="fields"
            responsive
            fixed
          >
            <template v-slot:cell(edit)="row">
              <b-button
                variant="danger"
                class="ml-1"
                @click="deleteOrg(row.item)"
              >
                <font-awesome-icon
                  :icon="['far', 'trash-alt']"
                  class="icon"
                />
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { createOrganization, deleteOrganization, getOrganizations } from '@/shared/api/organizations-api';
  import { displayNotification } from '@/shared/services/modal-service';

  export default {
    name: 'AppOrganizations',
    data: () => ({
      organizations: [],
      newOrganizationName: '',
      fields: [
        { key: 'id', label: 'Name' },
        { key: 'edit', label: 'Remove' },
      ],
    }),
    async created() {
      await this.refresh();
    },
    methods: {
      formValid() {
        return this.newOrganizationName.trim() !== '';
      },
      async refresh() {
        this.organizations = await getOrganizations();
      },
      async createOrg() {
        if (typeof this.newOrganizationName !== 'undefined' && this.newOrganizationName !== null && this.newOrganizationName.trim() !== '';) {
          await createOrganization({ id: this.newOrganizationName })
            .then(() => displayNotification(this, { message: 'Department created', variant: 'success' }))
            .then(this.refresh)
            .catch(({ error, message }) => displayNotification(this, { title: error, message, variant: 'danger' }));
        }
        else{
          displayNotification(this, { message: 'Department name is empty', variant: 'danger' })
        }
      },
      async deleteOrg(organization) {
        await deleteOrganization(organization.id)
          .then(() => displayNotification(this, { message: 'Organization deleted', variant: 'success' }))
          .then(this.refresh)
          .catch(({ error, message }) => displayNotification(this, { title: error, message, variant: 'danger' }));
      },
    },
  };
</script> -->
<template>
<div class="department_main">
  <b-modal
  id="new-organization-modal"
  title="New Department"
  ok-title="Create"
  auto-focus-button="ok"
  @ok="createOrg"
  @cancel="newOrganizationName = ''"
  @close="newOrganizationName = ''"
>
  <b-input
    v-model="newOrganizationName"
    autofocus
  />
</b-modal>
  <div class="department_table">
    <b-button
          id="org_btn"
          v-b-modal.new-organization-modal
          class="mb-4"
          variant="primary"
        >
          <!-- <font-awesome-icon
            icon="plus"
            class="icon"
          /> -->
          Create new Department
        </b-button>
    <Mastertable
    :fields="fields"
    :items="organizations"
    @remove-clicked="deleteOrg"
    />
  </div>
</div>
</template>
<script>
import { createOrganization, deleteOrganization, getOrganizations } from '@/shared/api/organizations-api';
import { displayNotification } from '@/shared/services/modal-service';
import Mastertable from '../../shared/components/mastertable/mastertable.vue';

  export default {
    name: 'AppOrganizations',
    components: { Mastertable },
    data: () => ({
      organizations: [],
      newOrganizationName: '',
      fields: [
        { key: 'id', label: 'Name' },
        { key: 'remove', label: 'Remove' },
      ],
    }),
    async created() {
      await this.refresh();
    },
    methods: {
      async refresh() {
        this.organizations = await getOrganizations();
      },
      async createOrg() {
        await createOrganization({ id: this.newOrganizationName })
          .then(() => displayNotification(this, { message: 'Organization created', variant: 'success' }))
          .then(this.refresh)
          .catch(({ error, message }) => displayNotification(this, { title: error, message, variant: 'danger' }));
      },
      async deleteOrg(organization) {
        await deleteOrganization(organization.id)
          .then(() => displayNotification(this, { message: 'Organization deleted', variant: 'success' }))
          .then(this.refresh)
          .catch(({ error, message }) => displayNotification(this, { title: error, message, variant: 'danger' }));
      },
    },
  };

</script>

<style scoped >

th {
    background-color: #065090;
    color: white;
}
#org_btn {
  border-radius: 3rem;
  color: white;
  overflow: hidden;
  padding: 5px 10px;
  transition: .10s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  background-color: #065090;
  margin-top: 10px;
}
#org_btn::after{
background-color: #ECAA0D;
border-radius: 5rem;
content: '';
display: block;
height: 100%;
width: 100%;
position: absolute;
left: 0;
top: 0;
transform: translate(-100%, 0) rotate(10deg);
transform-origin: top left;
transition: 0.3s transform ease-out;
will-change: transform;
z-index: -1;
}
#org_btn:hover::after{
transform: translate(0, 0);
}
#org_btn:hover{
color: white;
transform :scale(1.05);
will-change: transform;
}
.department_table{
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #dadce0;
  border-radius: 12px;
  width: 90%;
  margin: 0px auto;
  padding: 0px 15px;
  margin-bottom: 20px;
}
.block mt-3 th{
  background-color: #065090;
    color: white;
    text-align: center;
}
@media only screen and (max-width: 480px) {
  .department_table {
     background-color: white;
     box-shadow: none;
     border: none;
     border-radius: 0px;
     width: 100%;
     margin: 15px auto;
    }
  }
  @media only screen and (max-width: 768px) {
    .table_div {
      background-color: white;
      box-shadow: none;
      border: none;
      border-radius: 0px;
      width: 100%;
      margin: 15px auto;
    }
  }
</style>
