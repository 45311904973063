<template>
  <div class="user_main">
    <div class="user_con">
      <div class="user_btn_div mb-4">
      <b-row>
        <b-col>
          <b-button
          id="crt_new_user_btn"
          variant="primary"
          :to="{ name: 'new_user' }"
          class=""
          >
            <!-- <font-awesome-icon
              id="new_user_btn_icon"
              icon="user-plus"
              class="icon"
            /> -->
            Create new user
          </b-button>
        </b-col>
        <b-col
        class="text-right"
        >
          <b-button
          id="imprt_user_btn"
          variant="primary"
          :to="{ name: 'Importuser' }"
          class=""
          >
            <!-- <font-awesome-icon
              id="new_user_btn_icon"
              icon="user-plus"
              class="icon"
            /> -->
            Import Users
          </b-button>
        </b-col>
      </b-row>
      </div>

      <!-- <div class="container">
        <div class="panel panel-sm">
          <div class="panel-heading"> 
            <h4>CSV Import</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="csv_file" class="control-label col-sm-3 text-right">CSV file to import</label>
              <div class="col-sm-9">
                <input type="file" id="csv_file" accept=".csv" name="csv_file" class="form-control" @change="loadCSV($event)">
              </div>
            </div>
            <div class="col-sm-offset-3 col-sm-9">
              <div class="checkbox-inline">
                <label for="header_rows"><input type="checkbox" id="header_rows"> File contains header row?</label>
              </div>
            </div>
            
            <div class="col-sm-offset-3 col-sm-9">
              <a href="#" class="btn btn-primary">Parse CSV</a>
            </div>
            <table v-if="parse_csv">
              <thead>
                <tr>
                  <th v-for="key in parse_header"
                      @click="sortBy(key)"
                      :class="{ active: sortKey == key }">
                    {{ key | capitalize }}
                    <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
                    </span>
                  </th>
                </tr>
              </thead> 
              <tr v-for="csv in parse_csv">
                <td v-for="key in parse_header">
                  {{csv[key]}}
                </td>
              </tr>
              
            </table>
          </div>
        </div>  
      </div> -->
      <!-- <div
        class="float-right"
      >
        <input type="file" accept=".csv" @change="handleFileUpload" ref="fileInput" />
        <b-button 
          variant="primary"
          @click="uploadCSV"
        >
            Upload
        </b-button>
      </div> -->
      <!-- <b-button variant="outline-primary" class="ml-auto" id="user_assgn_btn">
        <font-awesome-icon icon="cog" class="icon" id="assgn_user_btn_icon" />
        Assign user to lab
      </b-button> -->

      <div class="block mt-3">
        <b-table
        id="usersTable"
        :items="users"
        :fields="fields"
        responsive
        fixed
        tbody-tr-class="user-row"
        >
          <template v-slot:cell(username)="row">
            <app-user-name :user="row.item" />
          </template>
          <template v-slot:cell(edit)="row">
            <b-button
            id="user_crt_form_btn"
            variant="primary"
            :to="{ name: 'user_edition', params: { user: row.item, username: row.item.username } }"
            >
              <font-awesome-icon
              icon="edit"
              class="icon"
              />
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getUsers,
  updateUser,
} from '@/shared/api/users-api';
import { getOrganizations } from '@/shared/api/organizations-api';
import { displayNotification } from '@/shared/services/modal-service';
import AppUserName from '@/pages/users/user-name.vue';
// import usecsvButton from "@usecsv/vuejs";

export default {
  name: 'AppUsers',
  components: { AppUserName },
  data: () => ({
    organizations: [],
    users: [],
    channel_name: '',
      channel_fields: [],
      channel_entries: [],
      parse_header: [],
      parse_csv: [],
      sortOrders:{},
      sortKey: '',
    fields: [
      { key: 'username', label: 'User', sortable: true },
      { key: 'role', label: 'Role', sortable: true },
      { key: 'organization.id', label: 'Organization', sortable: true },
      { key: 'edit' },
    ],
  }),
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  async created() {
    [this.users, this.organizations] = await Promise.all([getUsers(), getOrganizations()]);
  },
  methods: {
    saveUser(user) {
      updateUser(user)
        .then(() => displayNotification(this, { message: 'User saved', variant: 'success' }))
        .catch(({ error, message }) => displayNotification(this, { title: error, message, variant: 'danger' }));
    },
    // uploadCSV() {
    //   const file = this.$refs.fileInput.files[0];
    //   if (file) {
    //     console.log('Selected file:', file);
    //     uploadUserFile(file)
    //       .then(response => {
    //         // Handle the response or perform any other actions
    //         console.log('File uploaded successfully:', response);
    //       })
    //       .catch(error => {
    //         console.error('Error uploading file:', error);
    //       });
    //   } else {
    //     console.log('No file selected');
    //   }
    // },
    sortBy: function (key) {
      var vm = this
      vm.sortKey = key
      vm.sortOrders[key] = vm.sortOrders[key] * -1
    },
    csvJSON(csv){
      var vm = this
      var lines = csv.split("\n")
      var result = []
      var headers = lines[0].split(",")
      vm.parse_header = lines[0].split(",") 
      lines[0].split(",").forEach(function (key) {
        vm.sortOrders[key] = 1
      })
      lines.map(function(line, indexLine){
        if (indexLine < 1) return // Jump header line
        
        var obj = {}
        var currentline = line.split(",")
        
        headers.map(function(header, indexHeader){
          obj[header] = currentline[indexHeader]
        })
        
        result.push(obj)
      })
      result.pop() // remove the last item because undefined values
      return result // JavaScript object
    },
    loadCSV(e) {
      var vm = this
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        // Handle errors load
        reader.onload = function(event) {
          var csv = event.target.result;
          vm.parse_csv = vm.csvJSON(csv)
          
        };
        reader.onerror = function(evt) {
          if(evt.target.error.name == "NotReadableError") {
            alert("Canno't read file !");
          }
        };
      } else {
        alert('FileReader are not supported in this browser.');
      }
    }
  },
};
</script>

<style scoped>
.user_btn_div {
  padding: 10px 0px;
}
.user-row span,
.user-row td {
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.user_con {
  width: 90%;
  margin: auto;
  background: #FFFFFF;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 12px;
  border: 1px solid #dadce0;
  padding: 15px 15px;
  min-height: 350px;
}

#crt_new_user_btn {
  background-color: #065090;
  box-shadow: 0px 14px 26px rgba(72, 123, 177, 0.3);
  border-radius: 66px;
  color: white;
}
#imprt_user_btn{
  background-color: #065090;
  box-shadow: 0px 14px 26px rgba(72, 123, 177, 0.3);
  border-radius: 66px;
  color: white;
}

.user-row th {
  background-color: #065090;
  color: white;
  text-align: center;
}

.m-3,
.ml-auto {
  background-color: #06090D;
  box-shadow: 0px 14px 26px rgba(72, 123, 177, 0.3);
  border-radius: 66px;
}

#new_user_btn_icon,
#assgn_user_btn_icon {
  color: white;
}

#crt_new_user_btn, #imprt_user_btn {
  border-radius: 3rem;
  color: white;
  overflow: hidden;
  padding: 5px 10px;
  transition: .10s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  background-color: #065090;
}

#crt_new_user_btn::after {
  background-color: #ECAA0D;
  border-radius: 5rem;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.3s transform ease-out;
  will-change: transform;
  z-index: -1;
}
#imprt_user_btn::after{
  background-color: #ECAA0D;
  border-radius: 5rem;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.3s transform ease-out;
  will-change: transform;
  z-index: -1;
}

#crt_new_user_btn:hover::after {
  transform: translate(0, 0);
}
#imprt_user_btn:hover::after {
  transform: translate(0, 0);
}

#crt_new_user_btn:hover {
  color: white;
  transform: scale(1.05);
  will-change: transform;
}
#imprt_user_btn:hover {
  color: white;
  transform: scale(1.05);
  will-change: transform;
}
.panel {
  border: 2px solid #dfdfdf;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0 0;
  margin: 10px;
} 
.panel.panel-sm {
  max-width: 700px;
  margin: 10px auto;
}
.panel-heading {
  border-bottom: 2px solid #dfdfdf;
}
.panel-heading h1, .panel-heading h2, .panel-heading h3, .panel-heading h4, .panel-heading h5, .panel-heading h6 {
  margin: 0;
  padding: 0;
}
.panel-body .checkbox-inline {
  padding: 15px 20px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

@media only screen and (max-width: 480px) {
  .block {
    padding: 0px 10px 0px 10px;
  }

  .user_main {
    width: 100%;
  }

  .user_con {
    width: 100%;
    box-shadow: none;
    border: none;
    border-radius: 0%;
  }
}

@media only screen and (max-width: 768px) {
  .block {
    padding: 0px 10px 0px 10px;
  }

  .user_con {
    width: 100%;
    box-shadow: none;
    border: none;
    border-radius: 0%;
  }

  .user_main {
    width: 100%;
  }
}
</style>
