import axios from 'axios';

export const getUsers = async () => axios.get('/api/users');

export const getStudents = async () => axios.get('/api/users/students');

export const getUser = async (username) => axios.get(`/api/users/${username}`);

export const updateUser = async (user) => axios.put(`/api/users/${user.username}`, user);

export const changeUserPassword = async (username, password) => axios.put(
  `/api/users/${username}/password`,
  `password=${password}`,
);

export const createUser = async (user) => axios.post('/api/users', user);

export const deleteUser = async (userId) => axios.delete(`/api/users/${userId}`);

export const uploadBulkUser = async (userList, organization, role) => axios.post('/api/users/bulkUpload', { "userList": userList,"organization": organization, "role":role });
  // const formData = new FormData();
  // formData.append('file', file);
  // try {
  //   const response = 
  //   return response.data;
  // } catch (error) {
  //   throw new Error('File upload failed');
  // }
// };
