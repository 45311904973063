<template>
  <div class="mb-5">
    <h1 class="user_imprt_header">
      Import Bulk Users In CSV Format
    </h1>
      <div v-if="step === 1"  class="step_1">
        <div class="import_user_btn_div">
          <div
          class="bulb_as_btn"
          >
            <span  class="ml-3" @click="openModal">
             <font-awesome-icon icon="lightbulb" class="bulb_1"/>
           </span>
           <span
           class="read_me"
           >Read me</span>
          </div>
          <img src="../../assets/images/file.png" alt="here is image" />
          <span class="label_input">Select File</span>
              <label class="input-file mt-2">
                <b class="btn">
                  <font-awesome-icon icon="upload" />
                  <i class="material-icons"></i> Choose File</b>
                <input 
                type="file" 
                class="fileInput"
                name="csv_file"
                accept=".csv"
                @change="loadCSV($event)"
                multiple>
              </label>
              <div v-if="Object.keys(validationErrors).length === 0">
                <div v-for="file in selectedFiles" :key="file.name">
                  <p class="file_name mt-1 mb-1">{{ file.name }}</p>
                </div>
              </div>
        </div>
          <button v-if="parse_csv.length > 0"  class="next_button bg-primary" @click="nextStep">
          <font-awesome-icon icon="arrow-right" />
          </button>
      </div>

    <div v-if="step === 2" class="user_table_div">
      <b-row>
        <b-col
        md="2"
        sm="12"
        >
          <button v-if="parse_csv" class="prev_button bg-primary" @click="prevStep">
            <font-awesome-icon icon="arrow-left" />
          </button>
        </b-col>
        <b-col
        md="5"
        sm="12"
        >
          <b-form-group>
            <vue-multiselect
              v-model="org_value"
              :multiple="false"
              label="id"
              track-by="id"
              searchable
              placeholder="Select organizations"
              :options="organizations"
              v-if="isSuperAdmin"
            />
          </b-form-group>
        </b-col>
        <b-col
        md="5"
        sm="12"
        >
          <b-form-group>
            <vue-multiselect
              v-model="role"
              :multiple="false"
              label="id"
              track-by="id"
              searchable
              placeholder="Select Roll"
              :options="filteredOptions"
              v-if="isSuperAdmin || isAdmin"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div v-for="file in selectedFiles" :key="file.name">
        <h6
        class="file_name_step_2"
        >Data Review of {{ file.name }}</h6>
      </div>
      <div style="height: 300px; overflow: auto;">
        <b-table
          responsive
          :items="parse_csv"
          :fields="[...parse_header, { key: 'selected', label: 'Select' }]"
          show-empty
          empty-text="No data available"
        >
        <template #cell(selected)="row">
          <input
            v-model="row.item.selected"
            type="checkbox"
            @change="recordAdmitData(row.item)"
          >
        </template>
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </div>
      <b-row>
        <b-col
        class="d-flex justify-content-center p-3"
        >
              <button class="upload_button" @click="onUpload">
                <font-awesome-icon icon="upload" />Upload
              </button>
        </b-col>
      </b-row>      
    </div>
    <div class="mb-5">
      <b-modal id="modal-1" centred title="File Tips" ref="myModal">
        <template v-slot:modal-header>
          <div class="d-flex align-items-center">
            <span class="zoom-icon">
              <font-awesome-icon icon="lightbulb" class="bulb"/></span>
            <h5 class="modal-title ml-2">File Warning</h5>
          </div>
        </template>
        <template v-if="authorities.includes('ROLE_SUPERADMIN')">
          <p class="">File must be in CSV format</p>
          <p class="">File first line should be header</p>
          <p class="">Header fields are Username,Password</p>
          <button
          class="modal_btn"
          >SUPER ADMIN</button>
        </template>
        <template v-else-if="authorities.includes('ROLE_ADMIN')">
          <p class="">File must be in CSV format</p>
          <p class="">File first line should be header</p>
          <p class="">Header fields are Username,Password</p>
          <button
          class="modal_btn"
          >ADMIN</button>
        </template>
        <template  v-else-if="authorities.includes('ROLE_PROFESSOR')">
          <p>
            <p class="">File must be in CSV format</p>
            <p class="">File first line should be a header</p>
            <p class="">Header fields are Username,Password</p>
            <button
            class="modal_btn"
            >PROFESSOR</button>
          </p>
        </template>
      </b-modal> 
      <b-modal centered v-model="errorModal"  @ok="handleErrorModalOk" @cancel="handleErrorModalCancel"
       title="Validation Errors">
        <template v-for="(errors, role) in validationErrors">
          <ul>
            <li v-for="error in errors">{{ error }}</li>
          </ul>
        </template>
        <figure>
          <img 
          class="modal_cry_face"
          src="../../assets/images/crying-face.png" alt="here is img">
        </figure>
      </b-modal>
    </div>  
  </div>
</template>

<script>
import {getModule} from '@/shared/api/modules-api';
import Multiselect from 'vue-multiselect'
import { getAuthorities } from '@/shared/api/authentication-api';
import { getOrganizations } from '@/shared/api/organizations-api';
import { uploadBulkUser } from '@/shared/api/users-api';
import { displayNotification } from '@/shared/services/modal-service';

export default {
  name: 'Importuser',
  components: { Multiselect },
  data: () => ({
    channel_name: '',
    channel_fields: [],
    channel_entries: [],
    parse_header: [],
    parse_csv: [],
    sortOrders: {},
    sortKey: '',
    step: 1,
    selectedFiles: [],
    uploadResult: null,
    authorities: [],
    errorModal: false,
    validationErrors: {},
    organizations: [],
    role: '',
    module: null,
    org_value: '',

    options: [
        { id: 'Admin', label: 'Admin' },
        { id: 'Professor', label: 'Professor' },
      ],
    selectedUser: [],
  }),
  computed: {
    isSuperAdmin() {
      return this.authorities.includes('ROLE_SUPERADMIN');
    },
    isAdmin() {
      return this.authorities.includes('ROLE_ADMIN');
    },
    filteredOptions() {
      if (this.isSuperAdmin) {
        return this.options;
      } else if (this.isAdmin) {
        return this.options.filter(option => option.id === 'Professor');
      }
      return [];
    },
  },
  async created() {
    this.authorities = await getAuthorities();
    this.organizations = await getOrganizations();
    this.module = await getModule(this.moduleId);
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.$refs.myModal.show();
  //   });
  // },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  methods: {
    sortBy: function (key) {
      var vm = this;
      vm.sortKey = key;
      vm.sortOrders[key] = vm.sortOrders[key] * -1;
    },
csvJSON(csv) {
  var vm = this;
  var lines = csv.split('\n');
  var result = [];
  var headers = lines[0].split(',');

  if (headers.length === 1 && headers[0] === '') {
    return result;
  } else {
    lines.slice(1).forEach(function(line) {
      var obj = {};
      var currentline = line.split(',');

      if (currentline.length !== headers.length) {
        return; // Skip lines with different number of values
      }

      headers.forEach(function(header, index) {
        var key = header.trim(); // Trim the header to remove leading/trailing spaces
        var value = currentline[index].trim(); // Trim the value to remove leading/trailing spaces
        obj[key] = value;
      });

      result.push(obj);
    });

    return result;
  }
},
loadCSV(e) {
  const files = e.target.files;
  this.selectedUser = [];
  this.selectedFiles = Array.from(files);
  const vm = this;
  if (window.FileReader) {
    const reader = new FileReader();
    reader.readAsText(e.target.files[0]);
    // Handle errors load
    reader.onload = function (event) {
      const csv = event.target.result;
      if (csv.trim().length === 0) {
        vm.validationErrors = {
          ROLE_SUPERADMIN: ['The CSV file is empty.'],
        };
        vm.errorModal = true;
        return;
      }
      const lines = csv.split('\n');
      const headers = lines[0].split(',');

      const requiredFields = ['Username', 'Password'];
      const firstRowFields = headers.map((field) => field.trim().toLowerCase());

      // Check if all required fields are present
      const missingFields = requiredFields.filter(
        (field) => !firstRowFields.includes(field.toLowerCase())
      );
      if (missingFields.length > 0) {
        vm.validationErrors = {
          ROLE_SUPERADMIN: [`The CSV file does not contain all the required fields: ${missingFields.join(', ')}`],
        };
        vm.errorModal = true;
        return;
      }

      const expectedHeaders = ['username', 'password'];
      const normalizedHeaders = headers.map((header) => header.trim().toLowerCase());

      // Check if the header names match the expected headers
      const invalidHeaders = normalizedHeaders.filter(
        (header) => !expectedHeaders.includes(header)
      );
      if (invalidHeaders.length > 0) {
        vm.validationErrors = {
          error: [`The CSV file header is invalid. Expected fields: Username, Password.`],
        };
        vm.errorModal = true;
        return;
      }

      vm.parse_header = headers.map((header) => ({
        key: header.trim(),
        label: header.trim(),
      }));

      vm.parse_csv = lines
        .slice(1)
        .map((line) => {
          const values = line.split(',').map((value) => value.trim()); // Trim each value
          if (values.length !== headers.length) {
            return null; // Skip lines with a different number of values
          }
          const obj = {};
          headers.forEach((header, index) => {
            const key = header.trim();
            const value = values[index];
            obj[key] = value;
          });
          obj.selected = false; // Add 'selected' field with an initial value
          return obj;
        })
        .filter((item) => item !== null); // Remove null values from the result

      vm.uploadResult = vm.parse_csv;
    };
    reader.onerror = function (evt) {
      if (evt.target.error.name === 'NotReadableError') {
        vm.validationErrors = {
          error: ["Can't read file!"],
        };
        vm.errorModal = true;
      }
    };
  } else {
    vm.validationErrors = {
  error: ["FileReader is not supported in this browser."],
};
    vm.errorModal = true;
  }
},
    recordAdmitData(user) {
      if (!(this.selectedUser.includes(user))) {
        this.selectedUser.push(user);
      } else {
        this.selectedUser = this.selectedUser.filter((item) => item.username !== user.username);
      }
      // Optionally, you could also log the admitData array to the console after each change:
      console.log(this.selectedUser);
    },
    async onUpload() {
      await uploadBulkUser(this.selectedUser, this.org_value.id, this.role.id)
        .then(() => displayNotification(this, { title: 'Result', message: 'All users uploaded successfully', variant: 'success' }))
        .then(() => this.$router.push({ name: 'users' }))
        .catch((error) => displayNotification(this, { title: 'Result', message: error, variant: 'danger' }));
    },
    nextStep() {
      if (this.selectedFiles.length === 0) {
        // Show the reminder modal if no file is imported
        this.$bvModal.show('importReminderModal');
      } else {
        // Proceed to the next step
        this.step++;
      }
    },
    prevStep() {
      this.step--;
    },
    openModal() {
      this.$refs.myModal.show();
    },
    handleErrorModalOk() {
    // Function logic when "OK" is clicked
    console.log("OK clicked");
    // Reload the page
    // window.location.reload();
  },
  handleErrorModalCancel() {
    // Function logic when "Cancel" is clicked
    console.log("Cancel clicked");
    // Reload the page
    // window.location.reload();
  },
  },
};
</script>
<style scoped>
.read_me{
  margin-left: 1rem;
  font-size: 19px;
  font-weight: 400;
}
.ml-3{
  font-size: 25px;
}
.modal_cry_face{
  display: block;
  margin: auto;
  width: 60px;
  margin-top: 20px;
}
button.modal_btn {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 5px 5px;
  border-radius: 7px;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 0rem 10px;
}
.ml-2{
  color: red;
}
.bulb path{
  color: red;
  display: inline-block;
  animation: zoomInOut 2s infinite alternate;
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.bulb_1 path{
  color: red;
  display: inline-block;
  animation: zoomInOut 2s infinite alternate;
  font-size: 20px;
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.file_name_step_2{
  text-align: end;
}
.step_3_button_div{
  display: flex;
  justify-content: center;
  align-items: center;
}
button.upload_button {
  border: none;
  border-radius: 25px;
  padding: 10px 0px;
  background-color: #073065;
  font-size: 15px;
  font-weight: bold;
  color: white;
  width: 35%;
}
button.upload_button:hover{
  width: 40%;
  transition: 0.5s;
}
.button_div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.user_table_div {
  background-color: blue;
  width: 60%;
  margin: auto;
  background-color: white;
  box-shadow: 0px 0px 56px rgba(69,69,69,.2);
  border-radius: 10px;
  padding: 10px 10px;
}
#review_user_table{
  width: 100%;
}
.next_button {
  padding: 8px 10px;
  border: none;
  margin: auto;
  display: block;
  border-radius: 20px;
  box-shadow: 
  12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
  -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;
  width: 75px;
}
.prev_button{
  padding: 8px 10px;
  border: none;
  display: block;
  border-radius: 20px;
  box-shadow: 
  12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
  -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;
  width: 75px;
}
.input-file { 
  position:relative; 
  overflow:hidden;  
  padding:0;
  display:block;
  max-width:100%;
  cursor:pointer;
}

.input-file .btn { 
  white-space: nowrap;
  display: block;
  width: 50%;
  margin: auto;
  border-radius: 25px;
  padding: 10px 0px;
  background-color: #073065;
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.input-file .material-icons {
  float:left;
  font-size:16px; 
  line-height:inherit;
  margin-right:4px;
}

.input-file ins { 
  white-space:nowrap; 
  display: block;
  max-width:100%; 
  overflow:hidden; 
  text-overflow:ellipsis; 
  font-size:12px;
}

.input-file:after {
  content:""; 
  display:block; clear:both; 
} 

.input-file input { 
  width:0.1px; 
  height:0.1px;
  opacity:0;
  overflow:hidden;
  position:absolute; 
  top:-100px;
  z-index:-1; 
}
.step_1{
  width: 25%;
  margin: auto;
  box-shadow: 0px 0px 56px rgba(69,69,69,.2);
  border-radius: 10px;
  padding-bottom: 10px;
}
.step_3{
  width: 25%;
  margin: auto;
  box-shadow: 0px 0px 56px rgba(69,69,69,.2);
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.step_1 p{
  text-align: center;
}
.step_1 a{
  margin: auto;
  display: block;
}
.file_name{
  text-align: center;
}
.user_imprt_header{
    font-size: 3.25rem;
    font-family: Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,
    Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    font-weight: 700;
    text-align: center;
    color: #454545;
}
#review_user_table{
    width: 100%;
    margin: auto;
}
#review_user_table td {
  font-size: 15px;
  font-weight: 500;
  color: #232020;
}
.form-control {
    width: 100%;
    margin: auto;
    border: none;
}
.imprt_user_main{
    margin-bottom: 20px;
}
.import_user_btn_div{
    padding-bottom: 20px;
    padding-top: 10px;
}
.input-group{
    width: 80%;
    margin: auto;
}
.import_user_btn_div img{
    width: 50%;
    display: block;
    margin:  auto;

}
.step_3 img{
  width: 30%;
  display: block;
  margin:  auto;
} 
.label_input{
    margin: auto;
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
}
.form-control:focus{
    box-shadow: none;
}
@media only screen and (max-width: 480px) {
  .user_imprt_header {
    font-size: 1.5rem;
}
.step_1{
  width: 90%;
}
.user_table_div{
  width: 90%;
}
  }

@media only screen and (max-width: 768px) {
  .user_imprt_header {
    font-size: 2.1rem;
}
.step_1{
  width: 90%;
}
.user_table_div{
  width: 90%;
}
}
@media only screen and (max-width: 1024px) {
.step_1{
  width: 90%;
}
.user_table_div{
  width: 90%;
}
}

</style>
/* eslint-disable */
