<template>
  <div class="new_user_form_container">
    <div class="new_user">
      <div
        id="user_form_block"
        class="block"
      >
        <div class="block_head">
          <h2>New user</h2>
        </div>
        <div class="block_content">
          <b-form>
            <b-form-group
              label="Username"
              description="The username"
            >
              <b-form-input v-model="user.username" />
            </b-form-group>

            <b-form-group
              label="Password"
              description="Set the user password"
              class="mr-2"
            >
              <b-form-input
                v-model="user.password"
                type="password"
                :state="user.password && user.password === user.passwordConfirmation"
              />
            </b-form-group>
            <b-form-group
              label="Confirm password"
              description="Confirm the user password"
            >
              <b-form-input
                v-model="user.passwordConfirmation"
                type="password"
                :state="user.password && user.password === user.passwordConfirmation"
              />
            </b-form-group>

            <b-form-group
              label="Department"
              description="The user department"
            >
              <vue-multiselect
                v-model="user.organization"
                :options="organizations"
                track-by="id"
                label="id"
                :multiple="false"
                placeholder="Select user department"
                :show-labels="false"
                :disabled="user.role === 'superadmin'"
              />
            </b-form-group>

            <b-form-group
              label="Administrator"
              :description="user.admin === true ? 'The user is an administrator' : 'The user is not an administrator'"
            >
              <b-form-checkbox
                v-if="authorities.includes('ROLE_SUPERADMIN')"
                v-model="user.role"
                name="role"
                value="superadmin"
                switch
                size="lg"
                unchecked-value="user"
              >
                Super Admin
              </b-form-checkbox>

              <b-form-checkbox
                v-if="authorities.includes('ROLE_ADMIN')"
                v-model="user.role"
                name="role"
                value="admin"
                switch
                size="lg"
                unchecked-value="user"
              >
                Admin
              </b-form-checkbox>

              <b-form-checkbox
                v-if="authorities.includes('ROLE_ADMIN')"
                v-model="user.role"
                name="role"
                value="professor"
                switch
                size="lg"
                unchecked-value="user"
              >
                Professor
              </b-form-checkbox>

              <b-form-checkbox
                v-if="authorities.includes('ROLE_ADMIN')"
                v-model="user.role"
                name="role"
                value="student"
                switch
                size="lg"
                unchecked-value="user"
              >
                Student
              </b-form-checkbox>
            </b-form-group>
          </b-form>
        </div>

        <div class="block_content">
          <b-button
            id="new_user_save_btn"
            variant="primary"
            @click="createUser"
          >
            <!-- <font-awesome-icon
              icon="user-plus"
              class="icon"
            /> -->
            Create User
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrganizations } from '@/shared/api/organizations-api';
import { createUser } from '@/shared/api/users-api';
import { displayNotification } from '@/shared/services/modal-service';
import { getAuthorities } from '@/shared/api/authentication-api';

export default {
  name: 'AppUserEdition',
  data: () => ({
    organizations: [],
    user: {},
    authorities: [],
  }),
  async created() {
    this.organizations = await getOrganizations();
    this.authorities = await getAuthorities();
  },
  methods: {
    async createUser() {
      await createUser(this.user)
        .then(() => displayNotification(this, { message: 'User created', variant: 'success' }))
        .then(() => this.$router.push({ name: 'users' }))
        .catch(({ error, message }) => displayNotification(this, { title: error, message, variant: 'danger' }));
    },
  },
  watch: {
    'user.role': function(newRole) {
      if (newRole === 'superadmin') {
        this.user.organization = null;
      }
    }
  },
};
</script>

<style scoped>
.user-row td:first-child {
  font-size: 16px;
  vertical-align: middle;
}

#new_user_save_btn  {
  background-color: #FF9900;
  outline: none;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
}
#user_form_block{
  width: 70%;
  margin: auto;
  box-shadow: 0px 6px 25px rgba(6, 80, 144, 0.25);
  padding-bottom: 20px;
  border-radius: 2px;
  margin-bottom: 20px;
}
</style>
