<!-- <template>
  <div>
    <b-modal
    id="new-lab-modal"
    title="New User Group"
    ok-title="Create"
    auto-focus-button="ok"
    @ok="createLab"
    @cancel="newLabName = ''"
    @close="newLabName = ''"
    >
      <b-input
      v-model="newLabName"
      autofocus
      />
    </b-modal>
    <div class="lab_pg_container">
      <div class="lab_main">
        <b-button
          id="lab_crt_btn"
          v-b-modal.new-lab-modal
          variant="primary"
        >
          <font-awesome-icon
            icon="plus"
            class="icon"
          />
          Create new user-group
        </b-button>

        <div
          id="lab_list"
          class="block mt-3"
        >
          <b-table
            id="labsTable"
            :items="labs"
            :fields="fields"
            fixed
            responsive
          >
            <template v-slot:cell(id)="row">
            <app-lab-name
              :id="row.item.id"
            />
          </template>
            <template v-slot:cell(id)="row">
              <span>{{ row.item.id }}</span>
            </template>

            <template v-slot:cell(edit)="row">
              <b-button
                variant="danger"
                class="ml-1"
                @click="deleteLab(row.item)"
              >
                <font-awesome-icon
                  :icon="['far', 'trash-alt']"
                  class="icon"
                />
              </b-button>
            </template>

            <template v-slot:cell(assign)="row">
              <b-button
                variant="primary"
                :to="{ name: 'lab_edition', params: { lab: row.item, id: row.item.id } }"
              >
                <font-awesome-icon
                  icon="edit"
                  class="icon"
                />
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template> -->
<template>
<div class="labtable_main">
  <b-modal
    id="new-lab-modal"
    title="New Lab"
    ok-title="Create"
    auto-focus-button="ok"
    @ok="createLab"
    @cancel="newLabName = ''"
    @close="newLabName = ''"
    >
      <b-input
      v-model="newLabName"
      autofocus
      />
    </b-modal>
  <div class="labtable">
    <b-button
    id="lab_crt_btn"
    v-b-modal.new-lab-modal
    variant="primary"
    class="mb-4"
  >
    <!-- <font-awesome-icon
      icon="plus"
      class="icon"
    /> -->
    Create new user-group
  </b-button>
    <Mastertable
    :fields="fields"
    :items="labs"
    :delete-lab="deleteLab"
    @assign-clicked="redirectToStudentAssign"
    />
  </div>
</div>
</template>
<script>
import { getOrganizations } from '@/shared/api/organizations-api';
import { createLab, deleteLab, getLabs } from '@/shared/api/labs-api';
import { displayNotification } from '@/shared/services/modal-service';
import Mastertable from '../../shared/components/mastertable/mastertable.vue';

export default {
    name: 'AppLabs',
    components: { Mastertable },
    data: function data() {
      return {
        labs: [],
        organizations: [],
        newLabName: '',
        fields: [
            { key: 'id', label: 'Name' },
            { key: 'edit', label: 'Remove' },
            { key: 'assign' },
        ],
      };
    },
    async mounted() {
        this.organizations = await getOrganizations();
        await this.refresh();
    },
    async created() {
        await this.refresh();
    },
    methods: {
        async refresh() {
            this.labs = await getLabs();
        },
        async createLab() {
            await createLab({ id: this.newLabName, organization: this.organizations[0] })
                .then(() => displayNotification(this, { message: 'Lab created', variant: 'success' }))
                .then(this.refresh)
                .catch(({ error, message }) => displayNotification(this, { title: error, message, variant: 'danger' }));
        },
        async deleteLab(lab) {
        await deleteLab(lab.id)
      .then(() => {
        displayNotification(this, { message: 'Lab deleted', variant: 'success' });
        this.refresh();
      })
      .catch(({ error, message }) => {
        displayNotification(this, { title: error, message, variant: 'danger' });
      });
    },
    redirectToStudentAssign(lab) {
      this.$router.push({
        name: 'lab_edition',
        params: {
          labId: lab.id,
        },
      });
    },
  },
};
</script>
<style scoped >
#lab_crt_btn {
  border-radius: 3rem;
  color: white;
  overflow: hidden;
  padding: 5px 10px;
  transition: .10s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  background-color: #065090;
}
#lab_crt_btn::after{
background-color: #ECAA0D;
border-radius: 5rem;
content: '';
display: block;
height: 100%;
width: 100%;
position: absolute;
left: 0;
top: 0;
transform: translate(-100%, 0) rotate(10deg);
transform-origin: top left;
transition: 0.3s transform ease-out;
will-change: transform;
z-index: -1;
}

#lab_crt_btn:hover::after{
transform: translate(0, 0);
}
#lab_crt_btn:hover{
color: white;
transform :scale(1.05);
will-change: transform;
}
.labtable {
  width: 90%;
  margin: auto;
  padding: 15px 15px;
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #dadce0;
  border-radius: 20px;
  background-color: white;
  min-height: 400px;
}
@media only screen and (max-width: 480px) {
  .labtable {
      width: 100%;
      border: none;
      border-radius: 0%;
      box-shadow: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .labtable {
      width: 100%;
      box-shadow: none;
      border: none;
      border-radius: 0%;
    }
  }

</style>
